<template>
  <v-card id="consignee" class="mt-4">
    <v-card-title class="primary h5 text-white">{{ item.title }}</v-card-title>
    <v-card-text>
      <v-img :src="item.data" />
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "PopUpImage64",
  props: ["item"],
};
</script>
