<template>
  <div v-if="item.type === 'text'">
    <pop-up-text-group :item="item" />
  </div>
  <div v-else-if="item.type === 'table'">
    <pop-up-table :item="item" />
  </div>
  <div v-else-if="item.type === 'timeline'">
    <pop-up-time-line :item="item" />
  </div>
  <div v-else-if="item.type === 'docs'">
    <PopUpDocs :item="item" />
  </div>
  <div v-else-if="item.type === 'datatable'">
    <PopUpDataTable :item="item" />
  </div>
  <div v-else-if="item.type === 'datatable_with_search'">
    <PopUpDataTableWithSearch :itemprop="item" />
  </div>
  <div v-else-if="item.type === 'imageUrl'">
    <PopUpImage :item="item" />
  </div>
  <div v-else-if="item.type === 'imageBase64'">
    <PopUpImage64 :item="item" />
  </div>
</template>

<script>
import PopUpTable from "@/own/components/datatable/popup/PopUpTable.vue";
import PopUpDataTable from "@/own/components/fulfillment/asn/popup/PopUpDataTable.vue";
import PopUpTextGroup from "@/own/components/datatable/popup/PopUpTextGroup.vue";
import PopUpDocs from "@/own/components/datatable/popup/PopUpDocs.vue";
import PopUpTimeLine from "@/own/components/datatable/popup/PopUpTimeLine.vue";
import PopUpDataTableWithSearch from "@/own/components/datatable/popup/PopUpDataTableWithSearch.vue";
import PopUpImage from "@/own/components/datatable/popup/PopUpImage.vue";
import PopUpImage64 from "@/own/components/datatable/popup/PopUpImage64.vue";

export default {
  name: "PopUpSelector",
  props: ["item"],
  components: {
    PopUpTable,
    PopUpTextGroup,
    PopUpTimeLine,
    PopUpDocs,
    PopUpDataTable,
    PopUpDataTableWithSearch,
    PopUpImage,
    PopUpImage64,
  },
};
</script>
